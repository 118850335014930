@use "style-variables" as var;


[title^='Datenschutzeinstellungen'] {
  right: 16px !important;
  position: fixed !important;
  bottom: 16px !important;
}

.cookiefirst-root [data-cookiefirst-accent-color]:not([disabled]) {
  color: var.$primary !important;
}

.cookiefirst-root [data-cookiefirst-corner-style]:not([disabled]) {
  border-radius: 3px !important;
}

.cookiefirst-root [data-cookiefirst-widget]:not([disabled]) {
  border-radius: 3px !important;
}

.cookiefirst-root [data-cookiefirst-button="primary"]:not([disabled]) {
  background: var.$primary !important;
  border-color: var.$primary !important;
  border-radius: 3px !important;

  &:hover {
    color: var.$primary !important;
    background: white !important;
    border-color: var.$primary !important;
  }
}

@media(min-width: 768px) {
  .cookiefirst-root [data-cookiefirst-button="primary"]:hover {
    color: var.$primary !important;
    border-color: var.$primary !important;
    border-radius: 3px !important;
  }
}

.cookiefirst-root [data-cookiefirst-button="primary"][data-cookiefirst-action="save"],
.cookiefirst-root [data-cookiefirst-button="secondary"]:not([disabled]) {
  color: var.$primary !important;
  background: white !important;
  border-color: var.$primary !important;

  &:hover {
    color: white !important;
    background: var.$primary !important;
    border-color: var.$primary !important;
    border-radius: 3px !important;
  }
}

@media(min-width: 768px) {
  .cookiefirst-root [data-cookiefirst-button="primary"][data-cookiefirst-action="save"]:hover,
  .cookiefirst-root [data-cookiefirst-button="secondary"]:hover {
    color: var.$primary !important;
    background: white !important;
    border-color: var.$primary !important;
  }
  .cookiefirst-root [data-cookiefirst-widget="banner"]:not([disabled]) {
    border-radius: 3px !important;
  }
}

.cookiefirst-root [data-cookiefirst-accent-color][disabled] {
  color: var.$primary !important;
}
