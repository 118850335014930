@use "src/styles/style-variables" as var;

@mixin recrewt-card-style() {
  .mat-card {
    border-radius: 6px;
  }

  .mat-card.primary {
    background-color: var.$primary;
    color: white;

    .mat-card-subtitle {
      color: var.$on-primary-medium;
      opacity: 1;
    }
  }
}
