@use "src/styles/style-variables" as var;
@use 'node_modules/@angular/material' as mat;

@mixin recrewt-button-style() {
  .extended-fab-button {
    width: unset;
    padding: 0 26px;
    border-radius: 29px;
    position: fixed !important;
    bottom: 56px;
    right: 56px;

    .mat-icon.prefix {
      margin-right: 10px;
    }

    .mat-icon.suffix {
      margin-left: 10px;
    }

    .extended-fab-button__text {
      font-weight: 300;
      letter-spacing: .1em;
      text-transform: uppercase;
    }

    &.left {
      left: 56px;
      right: unset;
    }
  }

  .mat-mini-fab:not(.round-fab),
  .mat-button,
  .mat-flat-button,
  .mat-raised-button,
  .mat-stroked-button {
    border-radius: 6px;

    .mat-icon {
      margin-bottom: 4px;
    }

    &.big {
      padding: var.$nudge-amount * 0.5 var.$nudge-amount * 2;
    }

    &.jumbo {
      display: block;
      margin: var.$nudge-amount;
      padding: var.$nudge-amount var.$nudge-amount * 8;
    }
  }

  .mat-button,
  .mat-flat-button,
  .mat-raised-button,
  .mat-stroked-button {
    padding: 0 8px;
    line-height: 30px;
  }

  .mat-button-toggle-group-appearance-standard{
    border: none;
    @include mat.elevation(1);
  }
  mat-button-toggle-group.gradient-checked {
    .mat-button-toggle-checked {
      @extend .gradient-bg;
    }
  }
}
