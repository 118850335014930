@use 'src/styles/style-variables' as var;

@mixin recrewt-slide-toggle-style() {

  .mat-slide-toggle.overflow-visible {
    span.mat-slide-toggle-content {
      overflow: visible;
    }
  }

  .mat-slide-toggle.mat-checked.mat-primary:not(.mat-disabled) {
    .mat-slide-toggle-bar {
      background-color: var.$primary-100;
    }
  }

  .mat-slide-toggle.mat-checked.mat-accent:not(.mat-disabled) {
    .mat-slide-toggle-bar {
      background-color: var.$accent-100;
    }
  }

  .mat-slide-toggle.mirrored {
    .mat-slide-toggle-bar {
      transform: rotateY(180deg);
    }
  }

  .gradient-slider {
    .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
      background-color: var.$accent;
    }

    .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
      background-color: var.$accent-200;
    }

    .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
      background-image: linear-gradient(to right, var.$defaultPrimary, var.$defaultTertiary);
    }

    .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
      background-image: linear-gradient(to right, var.$defaultPrimary, var.$defaultTertiary);
    }

    .mat-slider.mat-slider-horizontal .mat-slider-wrapper {
      top: 18px;
    }

    .mat-slider.mat-slider-horizontal .mat-slider-track-wrapper {
      height: 8px;
      border-radius: 10px
    }

    .mat-slider.mat-slider-horizontal .mat-slider-track-background {
      background-color: #ff8f0077;
    }

    .mat-slider.mat-slider-horizontal .mat-slider-track-background,
    .mat-slider.mat-slider-horizontal .mat-slider-track-fill {
      height: 100%;
    }

    .mat-slider.mat-slider-horizontal .mat-slider-track-fill {
      background-image: linear-gradient(to bottom right, var.$defaultPrimary, var.$defaultTertiary);
    }

    .mat-accent .mat-slider-thumb {
      height: 24px;
      width: 24px;
      background-color: #404452;
      bottom: -15px;
      right: -15px;
    }

    .mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
      background-color: #404452;
      border: none;
    }

    .mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slide-toggle-bar {
      background-image: linear-gradient(to right, var.$defaultPrimary, var.$defaultTertiary);
    }

    .mat-slider-wrapper .mat-slider-thumb-container .mat-slider-thumb-label {
      background-image: linear-gradient(to right, var.$defaultPrimary, var.$defaultTertiary);
      color: white;
    }
  }
}
